import React from 'react';

function FeaturesBlocks() {
  return (
    <section className="relative"  data-aos="fade-right"  style={{background: "url(/face.png)"}}>

      {/* Section background (needs .relative class on parent and next sibling elements) */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-white">Ticket Types Available</h2>
            <p className="text-xl text-white">We have a range to accommodate different budgets.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto flex justify-center gap-6 md:grid-cols-2 lg:grid-cols-3 items-stretch md:max-w-2xl lg:max-w-none" style={{flexWrap: "wrap"}}>

            {/* 1st item */}
            <div className="w-full lg:w-1/4 relative flex flex-col items-center p-6 bggg rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="150">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Emma Student Standard Ticket</h4>
              <p className="text-white text-center"><span className="font-bold">£170</span>.<br />Available to students at Emmanuel College.</p>
            </div>

            <div className="w-full lg:w-1/4  relative flex flex-col items-center p-6 bggg rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Non-Emma Student Standard Ticket</h4>
              <p className="text-gray-800 text-center"><span className="font-bold">£185</span>.<br />For all other members of the University.</p>
            </div>

            {/* 2nd item */}

            
            <div className="w-full lg:w-1/4  relative flex flex-col items-center p-6 bggg rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Alumni Ticket</h4>
              <p className="text-gray-800 text-center"><span className="font-bold">£185</span>.<br />For all alumni of Emmanuel College.</p>
            </div>

            
            <div className="w-full lg:w-1/4  relative flex flex-col items-center p-6 bggg rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="350">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Queue Jump</h4>
              <p className="text-gray-800 text-center"><span className="font-bold">+£20</span>.<br />Add on to allow entry earlier than anyone else.</p>
            </div>

            {/* 3rd item */}
            

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;