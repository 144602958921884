import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './prelaunch/App';
import Home from './launch/Home';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// check if current date is after launch date or is development version
const launchDate = new Date('2024-02-04T22:00:00-00:00');
const currentDate = new Date(); 
if (currentDate > launchDate || process.env.REACT_APP_NODE_ENV === 'preview' || process.env.REACT_APP_NODE_ENV === 'development'|| process.env.NODE_ENV === 'development') {
root.render(
  <React.StrictMode>
    <Home />
  </React.StrictMode>
);
} else {
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
