export default ({name, photo, role, email, email2}) => {
    return (
<div className="w-full md:w-1/4 p-4 "  data-aos="zoom-y-out" data-aos-delay="150">
                          <a href={`mailto:${email}`} className=" hover:text-gray-300">
                          <div className="flex flex-col items-center justify-center h-full">
                              <img className="w-full h-64 rounded-full object-cover	photo" src={`people/${photo}`} alt={`${name} - ${role}`}/>
                              <h1 className="text-center text-2xl font-bold mt-4">{name}</h1>
                              <p className="text-center text-lg italic">{role}</p>
                              <p className="text-center text-lg underline"><a href={`mailto:${email}`} className=" hover:text-gray-400">{email}</a> {email2 && (<><a href={`mailto:${email2}`} className=" hover:text-gray-400">{email2}</a></>)}</p>
                          </div>
                          </a>
                      </div>
    )
}