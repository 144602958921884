import React, { useEffect } from "react";
import Header from "./Header";

import { ReactComponent as Gramophone } from "./emma.svg";
import { ReactComponent as Announce } from "./emmaannounce.svg";
import { ReactComponent as AnnounceBig } from "./emmaannounce2_2.svg";

import "add-to-calendar-button/assets/css/atcb.css";

import {
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { atcb_action } from "add-to-calendar-button";

import AOS from "aos";
import "aos/dist/aos.css";
import ReleaseTimeline from "./ReleaseTimeline";
import TicketTypes from "./TicketTypes";

function getScrollPercent() {
  var h = document.documentElement,
    b = document.body,
    st = "scrollTop",
    sh = "scrollHeight";
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
}
// function component
export default () => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    var tl = window.anime
      .timeline({ autoplay: true })

      .add({
        targets: "#EP2",
        opacity: [0.5, 1],
        scale: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      })
      .add({
        targets: "#EP1",
        opacity: [0.5, 1],
        scale: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      })

      .add({
        targets: "#EP3",
        opacity: [0.5, 1],
        scale: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      })

      .add({
        targets: "#EP4",
        opacity: [0.5, 1],
        scale: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      })
      .add({
        targets: "#hhg",
        opacity: [0.5, 1],
        scale: [0, 1],
        easing: "easeInOutExpo",
        duration: 700,
      });

    // window.addEventListener("scroll", () => {
    //const persentage = getScrollPercent();
    // tl.seek(tl.duration * (persentage * 0.09));
    //});
  }, [isMobile]);

  return (
    <div>
      <Header />
      <div
        className="calbut"
        style={{
          marginTop: isMobile ? -162 : 0,
          maxWidth: "800px",
          background: "#FDE2AB",
          padding: 30,
          borderRadius: 20,
          margin: "auto",
          border: "none",
          boxShadow: "none", background: "#83ad9b"
        }}
      >
        <div
          className="max-w-3xl mx-auto text-center pb-12 md:pb-16"
          id="headerr faq" style={{
            marginTop: "220px", background: "#83ad9b"}}
        >
        <h1 className="h2 mt-12 mb-4">Frequently Asked Questions</h1>
          <h1 className="h3 mb-4">When is Emma May Ball?</h1>
          <p className="text-xl text-gray-800">
            Emma May Ball will be hosted on the 16th of June 2024.
          </p>
          <h1 className="h3 mb-4">How many guest tickets can I purchase?</h1>
          <p className="text-xl text-gray-800">
          Current students at Emmanuel are allowed to purchase up to four guest tickets (not including themselves). Alumni are allowed to purchase up to three guest tickets. Other current students of the University are allowed to purchase up to two guest tickets. These guests are not required to be members of the University.
          </p>
          <h1 className="h3 mb-4">What is the dress code?</h1>
          <>
  <p className="text-xl text-gray-800">
  The dress code is Black Tie and formal national dress. </p>
</>


          <h1 className="h3 mb-4">Can I change the name on my ticket?</h1>
          <p className="text-xl text-gray-800">
          Yes, although there will be a name change fee of £10. This is available on the ticketing system.
          </p>

          <h1 className="h3 mb-4">
            Where can I read the terms and conditions?
          </h1>
          <p className="text-xl text-gray-800">
            The terms and conditions are available {" "}
            <a href="/terms.pdf" target="_blank" style={{textDecoration: "underline"}}>
              here
            </a>
          </p>

          <h1 className="h3 mb-4">
            What accessibility measures will be in place?
          </h1>
          <p className="text-xl text-gray-800">
            Read our preliminary access statement {" "}
            <a href="https://docs.google.com/document/d/1MWk32WmMDItO9XuQYk71iVwBNSOypUwb_Nj4AYwz7u0/edit?usp=sharing" target="_blank" style={{textDecoration: "underline"}}>
            here
            </a>.<br />
            For any questions, please email our
            accessibility officer, at{" "}
            <a href="mailto:access.emb84@gmail.com">access.emb84@gmail.com</a>.
          </p>

          <h1 className="h3 mb-4">What time does the event run to?</h1>
          <p className="text-xl text-gray-800">
          The event will run until 6:00 AM, at which point a survivors’ photo will be taken of all the guests who have made it until the end.
          </p>

          <h1 className="h3 mb-4">
            Will I receive a refund if the ball is cancelled?
          </h1>
          <p className="text-xl text-gray-800">
            Please see our ticketing policy in the terms and conditions linked
            above for more information.
          </p>
        </div>

        <br />
        <br />
      </div>

      <footer className="">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-600">
            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li className="ml-4">
                <a
                  href="https://www.facebook.com/emmanuelmayball22"
                  className="flex justify-center items-center text-gray-700 hover:text-gray-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Facebook"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.instagram.com/emmanuelmayball/"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
                  </svg>
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="https://www.tiktok.com/@emmamayweek"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <FaTiktok className="w-8 h-8 p-2 fill-current" />
                </a>
              </li>
              <li className="ml-4">
                <a
                  href="mailto:presidents.emb84@gmail.com"
                  className="flex justify-center items-center text-gray-700 hover:text-purple-900 bg-purple-300 hover:bg-purple-400 rounded-full shadow transition duration-150 ease-in-out"
                  aria-label="Instagram"
                >
                  <FaEnvelope className="w-8 h-8 p-2 fill-current" />
                </a>
              </li>
            </ul>

            {/* Copyrights note */}
            <div className="text-sm text-black mr-4">All rights reserved. Web design by <a href="https://souradip.com">souradip</a>.</div>
           </div>
        </div>
      </footer>
    </div>
  );
};
