import React from 'react';
import Contact from './Contact';

function SignIn() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Page content */}
        <main className="flex-grow" data-aos="zoom-y-out" data-aos-delay="150">
  
          <section className="bg-lines" style={{background: "url(/lines.png)"}}>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="pt-32 pb-12 md:pt-40 md:pb-20">
  <a href="#committee" name="committee"></a>
                {/* Page header bg-gradient-to-b from-yellow-600 to-yellow-800 */}
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <h1 className="h1 text-white">Our Committee</h1>
                </div>
  
                {/* Responsive grid of faces */}
                  <div className="flex flex-wrap justify-center text-white">
                      <Contact name="Uyen Bui and Odessa Chitty" role="Presidents" email="presidents.emb84@gmail.com" photo="ou.jpg"  />
                        <Contact name="Mia Becker" role="Access & Sustainability" email="access.emb84@gmail.com" photo="mia.jpg" email2="sustainability.emb84@gmail.com" />
                        <Contact name="Rosie Caddy and Athena Ganesh" role="Decor" email="decor.emb84@gmail.com" photo="ar.jpg" />
                        <Contact name="Katie Crowfoot and Nathaniel Kemp" role="Drinks" email="drinks.emb84@gmail.com" photo="nk.jpg" />
                        <Contact name="Orla Hill and Hitha Mannemela" role="Food" email="food.emb84@gmail.com" photo="ho.jpg" />
                        <Contact name="Alex Chilton and Bei Le Ng" role="Infrastructure & Logistics" email="infrastructure.emb84@gmail.com" photo="acbl.jpg" />
                        <Contact name="Jacob Lundie-Fallon and Toby Smallcombe" role="Music" email="music.emb84@gmail.com" photo="jt.jpg" />
                        <Contact name="Anna Partridge and Jasper Bates" role="Non-Musical Ents" email="entertainment.emb84@gmail.com" photo="ja.jpg" />
                        <Contact name="Tejal Paliya" role="Operations" email="ticketing.emb84@gmail.com" photo="tejal.jpg" email2="security.emb84@gmail.com" />
                        <Contact name="Katya Perry" role="Publicity and Sponsorship"  email="publicity.emb84@gmail.com" photo="katya.jpg" />
                        <Contact name="Sawen Ali" role="Staffing" email="staffing.emb84@gmail.com" photo="sa.jpg" />
                        <Contact name="Alex McManus" role="Treasurer" email="treasurer.emb84@gmail.com" photo="am.jpg" />

                   </div>
  
              </div>
            </div>
          </section>
  
        </main>
  
      </div>
    );
  }
  
  export default SignIn;