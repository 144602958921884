import React, { useState, useRef, useEffect } from 'react';

function Features() {

  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps

    
const pulses = window.gsap.timeline({
  defaults: {
    duration: 0.05, 
    autoAlpha: 1, 
    scale: 2, 
    transformOrigin: 'center', 
    ease: "elastic(2.5, 1)"
  }})
.to(".ball02, .text01", {}, 0.1) 
.to(".ball03, .text02", {}, 0.2)
.to(".ball04, .text03", {}, 0.3)
.to(".ball05, .text04", {}, 0.4)
.to(".ball06, .text05", {}, 0.5)

const main = window.gsap.timeline({defaults: {duration: 1},
  scrollTrigger: {
    trigger: "#svg11",
    scrub: true,
    start: "top center",
    end: "bottom center"
  }})
.to(".ball01", {duration: 0.1, autoAlpha: 1})
.from(".theLine", {drawSVG: 0}, 0)
.to(".ball01", {motionPath: {
  path: ".theLine", 
  align:".theLine",
  alignOrigin: [0.5, 0.5],
}}, 0).add(pulses, 0);

  }, [tab])

  return (
    <section className="relative s2" style={{marginTop: 20}} >


      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16" id="headerr">
            <h1 className="h2 mb-4">Ticket Release Schedule</h1>
            <p className="text-xl text-gray-800">They go pretty quick once they're released, so save the following dates.</p>
          </div>

          {/* Section content */}
          <div className="md:grid md:grid-cols-12 md:gap-6">
         
            {/* Tabs items */}
            <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-0" ref={tabs}>
              <div className="relative flex flex-col text-center lg:text-right">
                {/* Item 1 */}
                <svg id="svg11" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 600 1250">
  <path class="line01 line" d="M 10 200  600 200" ></path>
  <path class="line02 line" d="M 10 400  600 400" ></path>
  <path class="line03 line" d="M 10 600  600 600" ></path>
  <path class="line04 line" d="M 10 800  600 800" ></path>
  <path class="line05 line" d="M 10 1000  600 1000" ></path>
  <text class="text01" x="60" y="190">4th Feb</text>
  <text class="text01" x="450" y="190">Theme Release</text>
  <text class="text02" x="60" y="390">5th Feb</text>
  <text class="text02" x="480" y="390">Emma Release</text>
  <text class="text03" x="60" y="590">6th Feb</text>
  <text class="text03" x="380" y="590">Downing/Alumni Release</text>
  <text class="text04" x="60" y="790">7th Feb</text>
  <text class="text04" x="430" y="790">General Release</text>
  <text class="text05" x="60" y="990">16th Jun</text>
  <text class="text05" x="430" y="990">The Event</text>

  <path class="theLine" 
        d="M-5,0c230,116.2,344,230.6,342.2,343c-0.2,12.4-2.7,20.1-37.2,107C190.7,725.4,171.2,746.7,181,800
        c13.1,71.4,67.3,137.2,119,200c46.5,56.4,77.1,75.9,72,104c-8,44-82,76-222,96"
        fill="none" stroke="black" stroke-width="10px" />
  
  
  
  <circle class="ball ball01" r="20" cx="50" cy="100"></circle>
  <image class="ball ball02" x="258" y="178" width="80" height="40" href="/eye1.webp" />
  <image class="ball ball03" x="307" y="378" width="80" height="40" href="/eye2.webp" />
  <image class="ball ball04" x="200" y="578" width="80" height="40" href="/eye3.webp" />
  <image class="ball ball05" x="183" y="778" width="80" height="40" href="/eye4.webp" />
  <image class="ball ball06" x="263" y="978" width="80" height="40" href="/eye5.webp" />
</svg>
      
              </div>
            </div >
   



            {/* Content */}
            <div className="tll max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:pl-8" data-aos="fade-right">
              {/* Tabs buttons */}
              <div className="mb-8 md:mb-0 md:mt-32">
                <div
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-6`}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Theme Release</div>
                    <div className="text-gray-800">4th Feb 2024 at 10pm.</div>
                  </div>
                </div>
                <div
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-6`}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Release to Emma students</div>
                    <div className="text-gray-800">5th Feb 2024 at midday. We will release tickets to all current students of Emmanuel College to give all students a chance to attend.</div>
                  </div>
                </div>
                <div
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-6`}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Release to Downing and Alumni</div>
                    <div className="text-gray-800">6th Feb 2024 at midday. Our second batch of tickets will be released to Downing College students and all Emma alumni.</div>
                  </div>
                </div>
                <div
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-6`}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">General Release</div>
                    <div className="text-gray-800">7th Feb 2024 at midday. Our final batch of tickets to be released to all students at the University of Cambridge.</div>
                  </div>
                </div>
                <div
                  className={`flex items-center text-lg p-5 rounded border transition duration-300 ease-in-out mb-6`}
                >
                  <div>
                    <div className="font-bold leading-snug tracking-tight mb-1">Event</div>
                    <div className="text-gray-800">16th June 2024 at 9pm. We will allow entry from this time, with queue jump ticket holders entering first.</div>
                  </div>
                </div>
              </div>
            </div>

          </div >

        </div >
        
      </div >

      
    </section >
  );
}

export default Features;
